import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default class ScrollFunc {
    constructor() {
        this.init();
    }

    init() {
        this.onScroll();
        this.smoothScroll();
    }

    onScroll() {
        const funcElm = [...document.querySelectorAll('.js-scrollfunc')];

        funcElm.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: 'top 85%',
                //markers: true,
                onEnter: () => {
                    el.classList.add('-scrollin');
                },
            });
        });

        const windmillElm = [...document.querySelectorAll('.js-scrollwindmill')];

        windmillElm.forEach((el) => {
            ScrollTrigger.create({
                trigger: el,
                start: 'top 75%',
                //markers: true,
                onEnter: () => {
                    el.classList.add('-scrollin');

                    setTimeout(() => {
                        el.classList.remove('-scrollin');
                        el.classList.add('-anmend');
                    }, 800);
                },
            });
        });

        // // 読み込み後強制的に再計算させる
        // setTimeout(() => {
        //     ScrollTrigger.refresh();
        // }, 500);
    }

    smoothScroll() {
        const triggers = [...document.querySelectorAll('.js-smoothscroll')];
        const pagetop = [...document.querySelectorAll('.js-pagetop')];

        triggers.forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                const hash = el.getAttribute('href');
                const offset = window.innerWidth <= 834 ? window.innerWidth * 0.3 : 200 * (window.innerWidth / 1920);
                gsap.to(window, { duration: 1, scrollTo: { y: hash, offsetY: offset }, ease: 'circ.inOut' });
            });
        });

        pagetop.forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                gsap.to(window, { duration: 1, scrollTo: { y: 0 }, ease: 'circ.inOut' });
            });
        });
    }
}
