export function screenHeight() {
    const setHeight = () => {
        document.documentElement.style.setProperty("--screenHeight", `${window.innerHeight}px`);
        document.documentElement.style.setProperty("--pageHeight", `${document.body.clientHeight}px`);
    };

    //set Events
    setHeight();
    //window.addEventListener("resize", setHeight);
}
