import Swiper, { Pagination, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

export default class Slide {
    constructor() {
        this.init();
    }

    init() {
        this.setSwiper();
        this.setReverseSwiper();
    }

    setSwiper() {
        [...document.querySelectorAll('.js-swiper')].forEach(($slide) => {
            const opts = {
                modules: [Pagination],
                slidesPerView: 'auto',
                spaceBetween: 10,
                speed: 700,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    draggable: true,
                },
                observer: true,
                observeParents: true,
            };

            const swiper = new Swiper($slide, opts);
        });
    }

    setReverseSwiper() {
        [...document.querySelectorAll('.js-reverseswiper')].forEach(($slide) => {
            let swiperSlides = $slide.querySelectorAll('.swiper-slide');
            swiperSlides = Array.prototype.slice.call(swiperSlides, 0);
            const mySlideNth = swiperSlides.length;

            const opts = {
                modules: [Pagination, Navigation],
                slidesPerView: 'auto',
                // spaceBetween: 10,
                speed: 700,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    draggable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                observer: true,
                observeParents: true,
                initialSlide: mySlideNth - 1,
            };

            const swiper = new Swiper($slide, opts);
        });
    }
}
