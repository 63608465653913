export function gnav() {
    const open = [...document.querySelectorAll(".js-gnav_open")];
    const close = [...document.querySelectorAll(".js-gnav_close")];

    open.forEach((el) => {
        el.addEventListener("click", () => {
            document.body.classList.add("-gnav_open");
            document.body.classList.add("-lock");
        });
    });

    close.forEach((el) => {
        el.addEventListener("click", () => {
            document.body.classList.remove("-gnav_open");
            document.body.classList.remove("-lock");
        });
    });

    window.addEventListener("resize", () => {
        if (window.innerWidth > 1440 && document.body.classList.contains("-gnav_open")) {
            document.body.classList.remove("-gnav_open");
            document.body.classList.remove("-lock");
        }
    });

    //確認用
    //document.body.classList.add("-gnav_open");
}
