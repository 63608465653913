import { SpanWrap } from "../../util/spanwrap.es6";

export function pageheader() {
    const ttl = document.querySelector(".js-pageheadertxt");

    if (!ttl) {
        return;
    }

    new SpanWrap(ttl, "nestTrue"); //文字をspanでwrapする

    let loopCounter = 0;

    //ランダムに色を付け替える
    const setRandomClass = () => {
        const txts = [...document.querySelectorAll(".js-pageheadertxt .child")];

        txts.forEach((txt) => {
            txt.classList.remove("-fill");
            if (Math.random() >= 0.5) {
                txt.classList.add("-fill");
            }
        });

        if (loopCounter < 8) {
            setTimeout(setRandomClass, 200);
            loopCounter++;
        }
    };

    setRandomClass();
}
