//common funcs
import { screenHeight } from './common/function/screenheight.es6';
import { gnav } from './common/function/gnav.es6';
import { ready } from './common/function/ready.es6';
import { pageheader } from './common/function/pageheader.es6';
import { header } from './common/function/header.es6'

//common classes
import Modal from './common/classes/modal.es6';
import ScrollFunc from './common/classes/scrollfunc.es6';
import Movie from './common/classes/movie.es6';
import MovieSlide from './common/classes/movieslide.es6';
import Slide from './common/classes/slide.es6';
import Accordion from './common/classes/accordion.es6';

class Common {
    constructor() {
        // this.modal = new Modal();
        this.scrollFunc = new ScrollFunc();
        // this.movie = new Movie();
        // this.movieslide = new MovieSlide();

        window.modal = new Modal();
        window.movie = new Movie();
        window.movieslide = new MovieSlide();
        window.slide = new Slide();
        window.accordion = new Accordion();

        this.init();
    }

    init() {
        //common funcs
        screenHeight();
        gnav();
        ready();
        pageheader();
        header()
    }

    resetModalEvents() {
        this.modal.resetEvent();
    }
}

const common = new Common();
