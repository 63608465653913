import Swiper, { Navigation, Scrollbar } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

export default class MovieSlide {
    constructor() {
        this.init();
    }

    init() {
        this.setSwiper();
    }

    setSwiper() {
        [...document.querySelectorAll('.js-movieswiper')].forEach(($slide) => {
            const opts = {
                modules: [Navigation, Scrollbar],
                slidesPerView: 'auto',
                spaceBetween: 10,
                speed: 700,
                scrollbar: {
                    el: $slide.querySelector('.swiper-scrollbar'),
                    draggable: true,
                    hide: false,
                },
                navigation: {
                    nextEl: $slide.querySelector('.movieslide__ui__btn.-next'),
                    prevEl: $slide.querySelector('.movieslide__ui__btn.-prev'),
                },
                observer: true,
                observeParents: true,
            };

            const swiper = new Swiper($slide, opts);
        });
    }
}
