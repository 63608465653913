export function header() {
    let set_position = 0

    window.addEventListener('scroll', () => {
        const currentScrollPosition = document.documentElement.scrollTop

        // スクロール位置がトップの場合、処理をスキップ（iosバウンズ効果対策）
        if (currentScrollPosition <= 0) {
            set_position = 0
            return
        }

        if (set_position < currentScrollPosition) {
            document.body.classList.add('-headerhide')
        } else {
            document.body.classList.remove('-headerhide')
        }

        set_position = currentScrollPosition
    })
}
