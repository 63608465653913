export function ready() {
    //TOPはローダー有り
    const mv = document.querySelector('.mv');
    const loader = document.querySelector('.l-loader');

    const setReadyClass = () => {
        loader && loader.classList.remove('-active');
        document.body.classList.add('-ready');

    };

    if (mv) {
        const isNrw = window.innerWidth <= 865 ? '_nrw' : '';
        const mvImages = ['./assets/img/page/top/mainvisual/ph_main.png'];

        const loadFlags = [false];

        mvImages.forEach((el, index) => {
            const img = new Image();
            img.src = el;
            img.addEventListener('load', () => {
                loadFlags[index] = true;
            });
        });

        const loadLoop = () => {
            if (loadFlags[0] === true) {
                setTimeout(() => {
                    setReadyClass();
                }, 500);
            } else {
                setTimeout(loadLoop, 100);
            }
        };

        loadLoop();
    } else {
        //通常下層ページではローディングはしない
        setReadyClass();
    }
}
